import { useEffect, useState } from "react"

declare global {
    interface Window {
      gtag?: (...args: any[]) => void;
      dataLayer?: any[];
    }
}

export const useGoogleAnalytics = (
    gaMeasurementId: string | undefined,
  ) => {
    const [isInitialized, setIsInitialized] = useState(false)
    useEffect(() => {
      const loadScript = () => {
        // gaMeasurementId is optional so not all devs need it to run the app
        if (!window.gtag && gaMeasurementId) {
          // Create the script element
          const script = document.createElement('script')
          script.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`
          script.async = true
          // Append the script to the document
          document.head.appendChild(script)
          // Initialize gtag when the script is loaded - this could be done before
          script.onload = () => {
            window.dataLayer = window.dataLayer || []
            function gtag() {
              window.dataLayer?.push(arguments)
            }
            window.gtag = gtag
            window.gtag('js', new Date())
            window.gtag('config', gaMeasurementId, {
              debug_mode: false, // I keep this here to remember where to toggle debug
            })
            // Mark as initialized
            setIsInitialized(true)
          }
        } else {
          // gtag is already available, mark as initialized
          setIsInitialized(true)
        }
      }
      loadScript()
    }, [gaMeasurementId])
    return isInitialized
}