import { useEffect } from "react";
import { useLocation } from "react-router";
import { useGoogleAnalytics } from "#app/hooks/use-google-analytics.tsx";

const TRACKING_ID = "G-0FNGCVHF49"; // Replace with your Measurement ID

export function GoogleAnalytics() {
  const location = useLocation()

  const isGaInitialized = useGoogleAnalytics(
    TRACKING_ID
  )

  useEffect(() => {
    if (isGaInitialized && TRACKING_ID) {
      if (typeof window.gtag === 'function') {
        window.gtag('config', TRACKING_ID, {
          page_path: location.pathname,
        });
      }
    }
  }, [isGaInitialized, location])
  return null
}