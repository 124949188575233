import * as DialogPrimitive from "@radix-ui/react-dialog"
import { useState } from 'react'
import { Link, useFetcher } from 'react-router';
import { Button } from '#app/components/ui/button'
import {
	Dialog,
	DialogOverlay,
	DialogPortal,
} from '#app/components/ui/dialog'
import { useLanguage } from '#app/contexts/language-context.tsx';
import { type Accepted } from "#app/utils/connections.server.ts"
import { cn } from '#app/utils/misc.tsx'
import { Icon } from './ui/icon'

export default function CookieConsent({ acceptedTerms }:{ acceptedTerms:Accepted | null}) {
    if (acceptedTerms && acceptedTerms === 'true') return <></>;

	return (
        <CookieConsentModal />
    )
}

const CookieConsentModal = () => {
    const [isOpen, setIsOpen] = useState(true)
    const fetcher = useFetcher()
    const { currentLang } = useLanguage()

    const handleOpenChange = (open:boolean) => {
        // Only hide modal when clicking accept
    }

    const handleAccept = async () => {
        await fetcher.load('/api/accepted-terms-cookie');
        setIsOpen(false)
    }

	return (
		<Dialog open={isOpen} onOpenChange={handleOpenChange}>
			<DialogPortal>
				<DialogOverlay />
                <DialogPrimitive.Content 
                    className={cn(
                            'fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md',
                            !isOpen
                                ? 'translate-y-8 opacity-0 transition-[opacity,transform]'
                                : 'translate-y-0 opacity-100 transition-[opacity,transform]',
                    )}
                >
                
                        <div className="m-3 rounded-md border border-border bg-background shadow-lg dark:bg-card">
                            <div className="grid gap-2">
                                <div className="flex h-14 items-center justify-between border-b border-border p-4">
                                    <p className="text-lg font-medium">We use cookies</p>
                                    <Icon name="cookie" className="h-[1.2rem] w-[1.2rem]" />
                                </div>
                                <div className="p-4">
                                    <p className="text-start text-sm font-normal">
                                        We use cookies to ensure you get the best experience on our
                                        website. For more information on how we use cookies, please see
                                        our cookie policy.
                                        <br />
                                        <br />
                                        <span className="text-sm">
                                            By clicking "
                                            <span className="font-medium opacity-80">Accept</span>
                                            ", you agree to our use of cookies.
                                        </span>
                                        <br />
                                        <Link to={`/${currentLang}/privacy#cookies`} className="text-sm underline">
                                            Learn more.
                                        </Link>
                                    </p>
                                </div>
                                <div className="flex gap-2 border-t border-border p-4 py-5 dark:bg-background/20">
                                    <Button onClick={handleAccept} className="w-full">
                                        Accept
                                    </Button>
                                </div>
                            </div>
                        </div>
                </DialogPrimitive.Content>
			</DialogPortal>
		</Dialog>
	)
}