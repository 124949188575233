import { Link, useMatches } from 'react-router'
import logoImage from '#app/assets/logo.svg'
import { useLanguage } from '#app/contexts/language-context.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { type Theme } from '#app/utils/theme.server.ts'
import { Icon } from './ui/icon'

function Logo() {
	const { currentLang } = useLanguage()

	return (
		<Link
			to={`/${currentLang}`}
			className="group grid place-items-start leading-snug"
			data-testid="footer-logo"
		>
			<img src={logoImage} className="h-6 lg:h-7" />
		</Link>
	)
}

export const Footer = ({
	userPreference,
}: {
	userPreference: Theme | null
}) => {
	let { t, currentLang } = useLanguage()
	const matches = useMatches()
	const isMapPage = matches.find((m) => m.id === 'routes/($lang).map+/$')
	const isHomePage = matches.find(
		(m) => m.id === 'routes/($lang)._marketing+/index',
	)

	if (isMapPage || isHomePage) return <div data-testid="footer" />

	return (
		<div>
			<div className="bg-muted text-foreground">
				<div className="container flex max-w-screen-2xl items-center justify-between px-4 pt-5 md:px-6 md:pt-10">
					<div className="grid w-full grid-cols-1 gap-20 sm:grid-cols-2 lg:grid-cols-4">
						<div className="flex flex-col space-y-4">
							<Link
								to={`/${currentLang}/for-sale`}
								className="font-semibold decoration-red-500 decoration-4 underline-offset-8 hover:underline 4xl:text-xl"
							>
								{t('Common', 'Buy')}
							</Link>
							<Link
								to={`/${currentLang}/for-sale/houses/nicosia/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Houses for sale in Nicosia')}
							</Link>
							<Link
								to={`/${currentLang}/for-sale/flats/limassol/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Flats for sale in Limassol')}
							</Link>
							<Link
								to={`/${currentLang}/for-sale/land/paphos/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Land for sale in Paphos')}
							</Link>
							<Link
								to={`/${currentLang}/for-sale/offices/larnaca/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Offices for sale in Larnaca')}
							</Link>
						</div>
						<div className="flex flex-col space-y-4">
							<Link
								to={`/${currentLang}/to-rent`}
								className="font-semibold decoration-red-500 decoration-4 underline-offset-8 hover:underline 4xl:text-xl"
							>
								{t('Common', 'Rent')}
							</Link>
							<Link
								to={`/${currentLang}/to-rent/houses/nicosia/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Houses to rent in Nicosia')}
							</Link>
							<Link
								to={`/${currentLang}/to-rent/flats/limassol/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Flats to rent in Limassol')}
							</Link>
							<Link
								to={`/${currentLang}/to-rent/land/paphos/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Offices to rent in Paphos')}
							</Link>
							<Link
								to={`/${currentLang}/to-rent/offices/larnaca/all`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Offices to rent in Larnaca')}
							</Link>
						</div>
						<div className="flex flex-col space-y-4">
							<span className="font-semibold 4xl:text-xl">
								{t('Common', 'Company')}
							</span>
							<Link
								to={`/${currentLang}/about`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'About us')}
							</Link>
							<Link
								to={`/${currentLang}/agents/register`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Register real estate')}
							</Link>
							<Link
								to={`/${currentLang}/signup`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Register user')}
							</Link>
							<Link
								to={`/${currentLang}/login`}
								className="font-light decoration-red-500 decoration-2 underline-offset-4 hover:underline 4xl:text-lg"
							>
								{t('Common', 'Log in')}
							</Link>
						</div>
					</div>
				</div>

				<hr className="border-t-1 container my-12 max-w-screen-2xl dark:border-background" />

				<div className="container flex max-w-screen-2xl items-center justify-between px-4 pb-5 md:px-6">
					<div className="flex flex-wrap gap-4">
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/privacy`}
						>
							{t('Common', 'Privacy')}
						</Link>
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/privacy#cookies`}
						>
							{t('Common', 'Cookies Policy')}
						</Link>
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/contact-us`}
						>
							{t('Common', 'Contact us')}
						</Link>
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/about`}
						>
							{t('Common', 'About Spitakimu')}
						</Link>
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/sitemap`}
						>
							Sitemap
						</Link>
					</div>
					<div className="flex flex-wrap gap-4">
						<a href="https://www.facebook.com/spitakimu">
							<Icon size="lg" name="facebook" />
						</a>
						<a href="https://www.instagram.com/spitakimu.cy">
							<Icon size="lg" name="instagram" />
						</a>
					</div>
				</div>

				<div className="container flex max-w-screen-2xl flex-wrap items-center justify-between gap-4 px-4 pb-5 md:px-6">
					<div className="flex flex-wrap gap-4">
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/tos`}
						>
							{t('Common', 'Terms')}
						</Link>
						<Link
							className="font-semibold decoration-red-500 decoration-2 underline-offset-4 hover:underline"
							to={`/${currentLang}/members-terms`}
						>
							{t('Common', 'Member terms')}
						</Link>
					</div>
				</div>
			</div>
			<div className='flex flex-row container max-w-screen-2xl items-center justify-between px-4 md:px-6 py-4 space-x-2'>
				<Logo />
				<div className="w-full text-sm font-light">
					{t('Common', '© 2025 Spitakimu.com by APG - All rights reserved.')}
				</div>
				<ThemeSwitch userPreference={userPreference} formId='footer-theme-switch' />
			</div>
		</div>
	)
}
