export const FOR_SALE_NAVIGATION = [
	{
		id: '1',
		name: 'Houses for sale in Nicosia',
		href: '/for-sale/houses/nicosia/all',
	},
	{
		id: '2',
		name: 'Houses for sale in Larnaca',
		href: '/for-sale/houses/larnaca/all',
	},
	{
		id: '3',
		name: 'Houses for sale in Limassol',
		href: '/for-sale/houses/limassol/all',
	},
	{
		id: '4',
		name: 'Houses for sale in Paphos',
		href: '/for-sale/houses/paphos/all',
	},
	{
		id: '5',
		name: 'Flats for sale in Egkomi',
		href: '/for-sale/flats/nicosia/egkomi',
	},
	{
		id: '6',
		name: 'Flats for sale in Agia Zoni',
		href: '/for-sale/flats/limassol/agia-zoni',
	},
	{
		id: '7',
		name: 'Flats for sale in Geroskipou',
		href: '/for-sale/flats/paphos/geroskipou',
	},
	{
		id: '8',
		name: 'Offices for sale in Limassol',
		href: '/for-sale/offices/limassol/all',
	},
	{
		id: '9',
		name: 'Land for sale in Paphos',
		href: '/for-sale/land/paphos/all',
	},
]

export const TO_RENT_NAVIGATION = [
	{
		id: '10',
		name: 'Houses to rent in Nicosia',
		href: '/to-rent/houses/nicosia/all',
	},
	{
		id: '11',
		name: 'Houses to rent in Larnaca',
		href: '/to-rent/houses/larnaca/all',
	},
	{
		id: '12',
		name: 'Houses to rent in Limassol',
		href: '/to-rent/houses/limassol/all',
	},
	{
		id: '13',
		name: 'Houses to rent in Paphos',
		href: '/to-rent/houses/paphos/all',
	},
	{
		id: '14',
		name: 'Flats to rent in Egkomi',
		href: '/to-rent/flats/nicosia/egkomi',
	},
	{
		id: '15',
		name: 'Flats to rent in Agia Zoni',
		href: '/to-rent/flats/limassol/agia-zoni',
	},
	{
		id: '16',
		name: 'Flats to rent in Geroskipou',
		href: '/to-rent/flats/paphos/geroskipou',
	},
	{
		id: '17',
		name: 'Offices to rent in Limassol',
		href: '/to-rent/offices/limassol/all',
	},
	{
		id: '18',
		name: 'Land to rent in Paphos',
		href: '/to-rent/land/paphos/all',
	},
]