import ismobile from "is-mobile";
import { OpenImgContextProvider } from 'openimg/react'
import {
	data,
	Links,
	Meta,
	Outlet,
	redirect,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches,
} from 'react-router'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { type Route } from './+types/root.ts'
import appleTouchIconAssetUrl from './assets/favicons/apple-touch-icon.png'
import faviconAssetUrl from './assets/favicons/favicon.svg'
import CookieConsent from './components/cookie-consent.tsx'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { Footer } from './components/footer.tsx'
import { NavigationMenuRoot } from './components/navbar.tsx'
import { EpicProgress } from './components/progress-bar.tsx'
import { useToast } from './components/toaster.tsx'
import { href as iconsHref } from './components/ui/icon.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import { Toaster } from './components/ui/toaster.tsx'
import { LanguageProvider } from './contexts/language-context.tsx'
import { GoogleAnalytics } from './routes/resources+/google-analytics.tsx'
import {
	useOptionalTheme,
	useTheme,
} from './routes/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { getAcceptedTerms } from './utils/connections.server.ts'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { pipeHeaders } from './utils/headers.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { getLocale, supportedLanguages } from './utils/i18n.server.ts'
import { combineHeaders, getDomainUrl, getImgSrc } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { type Theme, getTheme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'

export const links: Route.LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		{
			rel: 'icon',
			href: '/favicon.ico',
			sizes: '48x48',
		},
		{ rel: 'icon', type: 'image/svg+xml', href: faviconAssetUrl },
		{ rel: 'apple-touch-icon', href: appleTouchIconAssetUrl },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: Route.MetaFunction = ({ data }) => {
	return [
		{ title: data ? 'Spitakimu' : 'Error | Spitakimu' },
		{
			property: 'og:title',
			content: 'Spitakimu',
		},
		{
			name: 'description',
			content: `Search for property with Cyprus' top real estate platform. Discover available houses and apartments for sale or rent, and connect with local estate agents in your area.`,
		},
	]
}

export async function loader({ request, params }: Route.LoaderArgs) {
	const timings = makeTimings('root loader')
	const locale = await getLocale(request, params.lang)
	const isMobile = ismobile({ ua: request.headers.get('user-agent') || "" });

	if(params.lang && !supportedLanguages.includes(params.lang)) {
		const url = new URL(request.url)

		throw redirect('/' + locale + url.pathname + url.search)
	}

	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { objectKey: true } },
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
							savedListings: { select: { listingId: true } },
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = await honeypot.getInputProps()

	const acceptedTerms = getAcceptedTerms(request)

	return data(
		{
			user,
			locale,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
			acceptedTerms,
			isMobile
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: Route.HeadersFunction = pipeHeaders

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
	locale,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string | undefined>,
	locale?:string
}) {
	const allowIndexing = ENV.ALLOW_INDEXING !== 'false'
  
	return (
		<html lang={locale} dir="ltr" className={`${theme} h-full overflow-hidden`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body className="bg-background text-foreground h-full">
				{allowIndexing && <GoogleAnalytics />}
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

export function Layout({ children }: { children: React.ReactNode }) {
	// if there was an error running the loader, data could be missing
	const data = useLoaderData<typeof loader | null>()
	const nonce = useNonce()
	const theme = useOptionalTheme()
	return (
		<Document nonce={nonce} theme={theme} env={data?.ENV} locale={data?.locale}>
			{children}
		</Document>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const theme = useTheme()
	const matches = useMatches()
	const isMapPage = matches.find((m) => m.id === 'routes/($lang).map+/$')
	const isHomePage = matches.find((m) => m.id === 'routes/($lang)._marketing+/index')
	
	useToast(data.toast)

	return (
		<OpenImgContextProvider
			optimizerEndpoint="/resources/images"
			getSrc={getImgSrc}
		>
			<div className="flex h-full flex-col">
				<NavigationMenuRoot userPreference={data.requestInfo.userPrefs.theme} isMobile={data.isMobile}/>

				<div className={`h-full overflow-${isMapPage ? 'hidden' : 'y-auto'} ${(!data.isMobile && !isMapPage && !isHomePage) ? '[scrollbar-gutter:stable_both-edges]' : ''} touch-auto`}>
					<main className="h-full font-poppins flex flex-col">
						<Outlet />
						<CookieConsent acceptedTerms={data.acceptedTerms} />
						<Footer userPreference={data.requestInfo.userPrefs.theme} />
					</main>
				</div>
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<Toaster />
			<EpicProgress />
		</OpenImgContextProvider>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<LanguageProvider language={data.locale}>
				<App />
			</LanguageProvider>
		</HoneypotProvider>
	)
}

export default AppWithProviders

// this is a last resort error boundary. There's not much useful information we
// can offer at this level.
export const ErrorBoundary = GeneralErrorBoundary
